import { gql } from 'graphql-request';

export const RESEARCH_NOTE_DATA_FRAGMENT_NAME = gql`ResearchNoteDataFragment`;
export const RESEARCH_NOTE_DATA_FRAGMENT = gql`
  fragment ${RESEARCH_NOTE_DATA_FRAGMENT_NAME} on ResearchNote {
    __typename
    analystRequestId
    analystThemeField
    createdAt
    evaluation {
      id
      instrument {
        id
        symbol
        exchange
        companyName
      }
    }
    evaluationField
    evaluationRequestId
    id
    index {
        id
        name
        symbol
    }
    indexId
    instrument {
        exchange
        currentSymbol
        companyName
        symbol
    }
    instrumentField
    instrumentId
    note
    organizationId
    title
    updatedAt
    url
    userId
  }
`;
export const GET_ORGANIZATION_RESEARCH_NOTES = gql`
    ${RESEARCH_NOTE_DATA_FRAGMENT}

    query getOrganizationResearchNotes($organizationId: ID!) {
        researchNotes(organizationId: $organizationId) {
          ...${RESEARCH_NOTE_DATA_FRAGMENT_NAME}
        }
    }
`;

export const GET_COMPANY_EVALUATION_RESEARCH_NOTES = gql`
    ${RESEARCH_NOTE_DATA_FRAGMENT}

    query getOrganizationResearchNotes($organizationId: ID!, $evaluationRequestIds: [ID!], $instrumentIds: [ID!]) {
        researchNotes(organizationId: $organizationId, evaluationRequestIds: $evaluationRequestIds, instrumentIds: $instrumentIds) {
          ...${RESEARCH_NOTE_DATA_FRAGMENT_NAME}
        }
    }
`;

export const GET_COMPANY_INFO_RESEARCH_NOTES = gql`
    ${RESEARCH_NOTE_DATA_FRAGMENT}

    query getOrganizationResearchNotes($organizationId: ID!, $instrumentIds: [ID!]) {
        researchNotes(organizationId: $organizationId, instrumentIds: $instrumentIds) {
          ...${RESEARCH_NOTE_DATA_FRAGMENT_NAME}
        }
    }
`;

export const GET_ANALYSIS_RESULTS_RESEARCH_NOTES = gql`
    ${RESEARCH_NOTE_DATA_FRAGMENT}

    query getOrganizationResearchNotes($organizationId: ID!, $analystRequestIds: [ID!]) {
        researchNotes(organizationId: $organizationId, analystRequestIds: $analystRequestIds) {
          ...${RESEARCH_NOTE_DATA_FRAGMENT_NAME}
        }
    }
`;

export const GET_INDEX_RESEARCH_NOTES = gql`
    ${RESEARCH_NOTE_DATA_FRAGMENT}

    query getOrganizationResearchNotes($organizationId: ID!, $indexIds: [ID!]) {
        researchNotes(organizationId: $organizationId, indexIds: $indexIds) {
          ...${RESEARCH_NOTE_DATA_FRAGMENT_NAME}
        }
    }
`;

export const GET_USER_RESEARCH_NOTES = gql`
    ${RESEARCH_NOTE_DATA_FRAGMENT}

    query getOrganizationResearchNotes($userId: ID!) {
        researchNotes(userId: $userId) {
          ...${RESEARCH_NOTE_DATA_FRAGMENT_NAME}
        }
    }
`;

export const GET_RESEARCH_NOTE = gql`
    ${RESEARCH_NOTE_DATA_FRAGMENT}

    query getResearchNote($id: ID!) {
      researchNote(id: $id) {
          ...${RESEARCH_NOTE_DATA_FRAGMENT_NAME}
      }
    }
`;

export const CREATE_RESEARCH_NOTE = gql`
  ${RESEARCH_NOTE_DATA_FRAGMENT}

  mutation createNewResearchNoteMutation($input: CreateResearchNoteInputType!) {
    createResearchNote(input: $input) {
      __typename
      ... on ResearchNote {
          ...${RESEARCH_NOTE_DATA_FRAGMENT_NAME}
      }
      ... on FieldErrors {
        errors {
          field
          message
        }
      }
    }
  }
`;

export const REMOVE_RESEARCH_NOTE = gql`
    mutation removeResearchNoteMutation($researchNoteId: ID!) {
        removeResearchNote(input: { researchNoteId: $researchNoteId })
    }
`;

export const UPDATE_RESEARCH_NOTE = gql`
    ${RESEARCH_NOTE_DATA_FRAGMENT}

    mutation updateResearchNoteMutation($input: UpdateResearchNoteInput!) {
      updateResearchNote(input: $input) {
        __typename
        ... on FieldErrors {
          errors {
            field
            message
          }
        }

        ... on ResearchNote {
            ...${RESEARCH_NOTE_DATA_FRAGMENT_NAME}
        }
      }
    }
`;
