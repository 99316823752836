import { constructResearchAnalysisPromptUrl } from '@/helpers/analysis';
import { DeepDiveUrlParams, getCompanyPageUrls, GetCompanyPageUrlsType, getDeepDiveUrl } from '@/helpers/company';
import { constructTeamPageUrl } from '@/helpers/organization';
import { getStringForEncoding } from '@/helpers/string';
import { SITE_BASE_URL } from '@/lib/constants';
import {
    ANALYSIS_TYPE_QUERY_PARAM_KEY,
    ANALYST_PATH,
    ANALYST_REQUEST_ID_QUERY_PARAM_KEY,
    PROMPT_QUERY_PARAM_KEY,
    RESEARCH_ANALYSIS_TYPE_QUERY_PARAM_KEY,
} from '@/lib/constants/analyst';
import { MainActionTabs, START_PAGE_TAB_VIEW_QUERY_PARAM } from '@/lib/constants/navigation';
import { TeamViews } from '@/lib/constants/team';

type PathOptions = {
    includeBaseUrl?: boolean;
};

// TODO: Use constructTeamUrls and TeamViews

export const GetOrganizationPublicRedirectPath = (organizationId: string, pathOptions: PathOptions = {}): string => {
    const url = constructTeamPageUrl({ organizationId: organizationId.toLowerCase() });

    return applyPathOptions(url, pathOptions);
};

export const GetIndexSymbolPublicPath = (symbol: string, pathOptions: PathOptions = {}): string => {
    return applyPathOptions(`/index/${encodeURIComponent(symbol.toLowerCase())}`, pathOptions);
};

export const GetIndexSymbolPercentGraphEmbedPath = (symbol: string, pathOptions: PathOptions = {}): string => {
    return applyPathOptions(`/embed/index/percent-graph/${encodeURIComponent(symbol.toLowerCase())}`, pathOptions);
};

export const GetAnalystPublicPath = (requestId: string, pathOptions: PathOptions = {}): string => {
    const url = constructResearchAnalysisPromptUrl('', requestId);
    return applyPathOptions(url, pathOptions);
};
export const GetDeepDivePublicPath = ({
    instrumentId,
    requestId,
    resultTheme,
    themeName,
    useNewUrl = false,
    pathOptions = {},
}: DeepDiveUrlParams & { pathOptions?: PathOptions }) => {
    const url = getDeepDiveUrl({ instrumentId, requestId, resultTheme, themeName, useNewUrl });

    return applyPathOptions(url, pathOptions);
};
export const GetCompanyPagePublicPath = ({
    ticker,
    exchange,
    analystRequestId,
    expandedModulePagePath,
    requestId,
    resultTheme,
    themeName,
    pathOptions = {},
}: GetCompanyPageUrlsType & { pathOptions?: PathOptions }) => {
    const url = getCompanyPageUrls({
        analystRequestId,
        exchange,
        expandedModulePagePath,
        requestId,
        resultTheme,
        themeName,
        ticker,
    });

    return applyPathOptions(url, pathOptions);
};

const applyPathOptions = (path: string, { includeBaseUrl = false }: PathOptions = {}) => {
    const prefix = includeBaseUrl ? SITE_BASE_URL : '';
    return `${prefix}${path}`;
};

export const navigateWithoutRerender = ({ pathname }: { pathname: string }) => {
    if (typeof window !== 'undefined') {
        // window.history.replaceState({}, '', pathname);
        window.history.replaceState({ ...window.history.state, as: pathname, url: pathname }, '', pathname);
    }
};

interface ConstructResearchAnalysisPathArgs {
    researchId?: string;
    prompt?: string;
    queryParams?: Record<string, string>;
}
export const constructResearchAnalysisPath = ({
    researchId,
    prompt,
    queryParams,
}: ConstructResearchAnalysisPathArgs): string => {
    const path = ANALYST_PATH;
    let queryString = `${ANALYSIS_TYPE_QUERY_PARAM_KEY}=${RESEARCH_ANALYSIS_TYPE_QUERY_PARAM_KEY}`;

    if (prompt) {
        queryString += `&${PROMPT_QUERY_PARAM_KEY}=${encodeURIComponent(getStringForEncoding(prompt))}`;
    }

    if (researchId) {
        queryString += `&${ANALYST_REQUEST_ID_QUERY_PARAM_KEY}=${encodeURIComponent(researchId)}`;
    }

    if (queryParams) {
        const paramsCopy = new URLSearchParams(queryString);

        for (const key in queryParams) {
            paramsCopy.append(key, queryParams[key]);
        }

        queryString = paramsCopy.toString();
    }

    return `${path}?${queryString}`;
};

interface GetAppStartPathParams {
    organizationId?: string;
    mainActionTab: MainActionTabs;
    isLoggedIn: boolean;
}

export const getAppStartPath = ({ organizationId, mainActionTab, isLoggedIn }: GetAppStartPathParams) => {
    if (isLoggedIn && organizationId) {
        const basePath = constructTeamPageUrl({ organizationId, teamView: TeamViews.DASHBOARD });

        return `${basePath}?${START_PAGE_TAB_VIEW_QUERY_PARAM}=${mainActionTab}`;
    }

    return `/app/start?${START_PAGE_TAB_VIEW_QUERY_PARAM}=${mainActionTab}`;
};
