export enum TooltipPosition {
    TOP_CENTER = 'top-center',
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM_CENTER = 'bottom-center',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
    RIGHT = 'right',
    LEFT = 'left',
}

export const tooltipPositionMap = {
    [TooltipPosition.TOP_CENTER]: '-top-2 left-1/2 transform -translate-x-1/2 -translate-y-full',
    [TooltipPosition.TOP_LEFT]: '-top-2 left-0 transform -translate-y-full',
    [TooltipPosition.TOP_RIGHT]: '-top-2 right-0 transform -translate-y-full',
    [TooltipPosition.BOTTOM_CENTER]: 'bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full',
    [TooltipPosition.BOTTOM_LEFT]: 'bottom-0 left-0 transform translate-y-full',
    [TooltipPosition.BOTTOM_RIGHT]: 'bottom-0 right-0 transform translate-y-full',
    [TooltipPosition.RIGHT]: 'top-1/2 -right-2 transform translate-x-full -translate-y-1/2',
    [TooltipPosition.LEFT]: 'top-1/2 -left-2 transform -translate-x-full -translate-y-1/2',
};
