'use client';

import React, { createContext, useContext, useEffect } from 'react';

import { useGlobalState } from '@/components/global/global-state';
import { constructTeamPageUrl } from '@/helpers/organization';
import { TeamViews } from '@/lib/constants/team';
import { useNextCookieState } from '@/lib/next-cookie-state';

export interface CurrentOrganizationInterface {
    canSelectCurrentOrganization: boolean;
    currentOrganizationId: string;
    currentOrganizationAnalysisResultsUrl: string;
    currentOrganizationCompanyEvaluationsUrl: string;
    currentOrganizationIndicesUrl: string;
    currentOrganizationMembersUrl: string;
    currentOrganizationProfileUrl: string;
    currentOrganizationProjectsUrl: string;
    currentOrganizationResearchUrl: string;
    currentOrganizationDashboardUrl: string;
    setCurrentOrganizationId: ReactStateSetter<string>;
}

const CurrentOrganizationContext = createContext({} as CurrentOrganizationInterface);

const CurrentOrganizationProvider = ({ children }: { children: React.ReactNode }) => {
    const { globalState } = useGlobalState();
    const { currentUser, currentUserLoading } = globalState;

    const [currentOrganizationId, setCurrentOrganizationId] = useNextCookieState<string>('currentOrganization', '');

    const canSelectCurrentOrganization = !!currentUser?.isThematicAdmin;

    useEffect(() => {
        if (currentUserLoading) return;

        if (!currentUser) {
            setCurrentOrganizationId('');
            return;
        }

        if (!currentOrganizationId || !canSelectCurrentOrganization) {
            setCurrentOrganizationId(currentUser.primaryOrganization?.id || '');
        }
    });

    const currentOrganizationIndicesUrl =
        currentOrganizationId &&
        constructTeamPageUrl({ organizationId: currentOrganizationId, teamView: TeamViews.INDICES });
    const currentOrganizationMembersUrl =
        currentOrganizationId &&
        constructTeamPageUrl({ organizationId: currentOrganizationId, teamView: TeamViews.MEMBERS });
    const currentOrganizationProfileUrl =
        currentOrganizationId &&
        constructTeamPageUrl({ organizationId: currentOrganizationId, teamView: TeamViews.PROFILE });
    const currentOrganizationResearchUrl =
        currentOrganizationId &&
        constructTeamPageUrl({ organizationId: currentOrganizationId, teamView: TeamViews.RESEARCH });
    const currentOrganizationDashboardUrl =
        currentOrganizationId &&
        constructTeamPageUrl({ organizationId: currentOrganizationId, teamView: TeamViews.DASHBOARD });
    const currentOrganizationProjectsUrl =
        currentOrganizationId &&
        constructTeamPageUrl({ organizationId: currentOrganizationId, teamView: TeamViews.PROJECTS });
    const currentOrganizationAnalysisResultsUrl =
        currentOrganizationId &&
        constructTeamPageUrl({ organizationId: currentOrganizationId, teamView: TeamViews.ANALYSIS_RESULTS });
    const currentOrganizationCompanyEvaluationsUrl =
        currentOrganizationId &&
        constructTeamPageUrl({ organizationId: currentOrganizationId, teamView: TeamViews.COMPANY_EVALUATIONS });

    return (
        <CurrentOrganizationContext.Provider
            value={{
                canSelectCurrentOrganization,
                currentOrganizationAnalysisResultsUrl,
                currentOrganizationCompanyEvaluationsUrl,
                currentOrganizationDashboardUrl,
                currentOrganizationId,
                currentOrganizationIndicesUrl,
                currentOrganizationMembersUrl,
                currentOrganizationProfileUrl,
                currentOrganizationProjectsUrl,
                currentOrganizationResearchUrl,
                setCurrentOrganizationId,
            }}
        >
            {children}
        </CurrentOrganizationContext.Provider>
    );
};

const useCurrentOrganization = () => {
    const context = useContext(CurrentOrganizationContext);
    if (!context) {
        throw new Error('useCurrentOrganization must be used within a CurrentOrganizationContext');
    }
    return context;
};

export { CurrentOrganizationProvider, useCurrentOrganization };
