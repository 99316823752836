'use client';

import Link from 'next/link';

import Button, { type ButtonTrackingProps, ButtonTypes } from '@/components/global/button';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import cn from '@/lib/cn';
import { type BaseTrackingProperties, type NavLinkWithTextLabel } from '@/types/tracking';

export const getFeaturedButtonClassName = (isDisabled = false) =>
    cn(
        'group/featured-button font-brand-md text-white hover:text-analyst-lavender-medium visited:text-white transition-colors',
        { 'bg-brand-gray': isDisabled, 'bg-gradient-to-r from-thematic-purple to-thematic-blue': !isDisabled }
    );

interface FeaturedButtonProps {
    className?: string;
    tabIndex?: number;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    children: React.ReactNode;
    isDisabled?: boolean;
    tracking?: ButtonTrackingProps;
}

export const FeaturedButton = ({
    className,
    onClick,
    tabIndex,
    children,
    isDisabled,
    tracking = {} as ButtonTrackingProps,
}: FeaturedButtonProps) => {
    const { components } = usePosthogTracking();

    return (
        <Button
            type={ButtonTypes.Action}
            tabIndex={tabIndex}
            onClick={onClick}
            roundedCorners="full"
            color="transparent"
            isDisabled={isDisabled}
            className={cn(getFeaturedButtonClassName(isDisabled), className)}
            tracking={{
                eventType: tracking?.eventType,
                trackingProperties: {
                    ...tracking?.trackingProperties,
                    component: components.FEATURED_BUTTON,
                } as BaseTrackingProperties,
            }}
        >
            {children}
        </Button>
    );
};

interface FeaturedButtonLinkProps {
    className?: string;
    tabIndex?: number;
    href: string;
    title?: string;
    target?: string;
    children: React.ReactNode;
    tracking?: ButtonTrackingProps;
}

export const FeaturedLinkButton = ({
    className,
    href,
    target = '_self',
    title,
    tabIndex,
    children,
    tracking,
}: FeaturedButtonLinkProps) => {
    const { components, trackNavigationLinkClick } = usePosthogTracking();
    const trackingAction = () => {
        tracking &&
            trackNavigationLinkClick({
                eventType: tracking?.eventType,
                trackingProperties: {
                    ...tracking?.trackingProperties,
                    component: components.FEATURED_LINK_BUTTON,
                    href,
                } as NavLinkWithTextLabel,
            });
    };

    return (
        <Link
            href={href}
            title={title}
            target={target}
            tabIndex={tabIndex}
            onClick={trackingAction}
            className={cn(
                'no-underline group/featured-button-link font-brand-md bg-gradient-to-r from-thematic-purple to-thematic-blue text-white hover:text-analyst-lavender-medium visited:text-white transition-colors rounded-full px-4 py-2.5',
                className
            )}
        >
            {children}
        </Link>
    );
};
