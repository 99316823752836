'use client';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

import { Paragraph } from '@/components/dom/text-elements';
import { useNewFeatureAnnouncement } from '@/components/providers/NewFeature';
import IconButton from '@/components/ui/IconButton';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import cn from '@/lib/cn';
import { NewFeatureAnnouncementsStorageKeyMap, NewFeatureAnnouncementTypes } from '@/lib/constants/new-features';
import { TooltipPosition, tooltipPositionMap } from '@/lib/constants/tooltip';
import { type NewFeatureProperties } from '@/types/tracking';

const NEW_FEATURE_OVERLAY_CLASSNAMES = ['opacity-100', 'transition-opacity', 'z-100'];

export interface NewFeatureSequentialTooltipProps {
    featureKey: NewFeatureAnnouncementTypes;
    hideOverlay?: boolean;
    isLast?: boolean;
}
interface NewFeatureTooltipProps {
    featureDescription: Array<React.ReactNode>;
    children?: React.ReactNode;
    className?: string;
    descriptionClassName?: string;
    tooltipPosition?: TooltipPosition;
    tooltipClassName?: string;
    featureKey: NewFeatureAnnouncementTypes;
    showTooltip?: boolean;
    tagCopy?: React.ReactNode;
    onClose?: () => void;
    hideOverlayOnClose?: boolean;
    hideOverlay?: boolean;
    sequential?: Array<NewFeatureSequentialTooltipProps>;
}

export const NewFeatureTooltip: React.FC<NewFeatureTooltipProps> = ({
    featureDescription,
    className,
    descriptionClassName,
    tooltipClassName,
    tooltipPosition = TooltipPosition.TOP_LEFT,
    children,
    featureKey,
    showTooltip = true,
    tagCopy = 'New feature',
    onClose,
    hideOverlay = false,
    hideOverlayOnClose = true,
}) => {
    const { newFeatures, updateNewFeatures } = useNewFeatureAnnouncement();
    const copyRef = useRef<HTMLDivElement>(null);
    const { eventTypes, components } = usePosthogTracking();
    const [descriptionText, setDescriptionText] = useState('');
    const [overlayElem, setOverlayElem] = useState<HTMLElement | null>(null);
    const hideTooltip = useCallback(() => {
        updateNewFeatures(featureKey, true);
        onClose?.();
        hideOverlayOnClose && overlayElem?.classList.remove(...NEW_FEATURE_OVERLAY_CLASSNAMES);
    }, [featureKey, hideOverlayOnClose, onClose, overlayElem, updateNewFeatures]);
    const hasViewedTooltip: boolean = useMemo(
        () => newFeatures[NewFeatureAnnouncementsStorageKeyMap[featureKey] as NewFeatureAnnouncementTypes],
        [featureKey, newFeatures]
    );
    const previousShowTooltipState = usePrevious(showTooltip);
    const previousHasViewedTooltipState = usePrevious(hasViewedTooltip);
    const showTooltipState = showTooltip && !hasViewedTooltip;
    const hideTooltipState =
        (!previousHasViewedTooltipState && hasViewedTooltip) || (previousShowTooltipState && !showTooltip);

    // Convert description react nodes to strings for tracking
    useEffect(() => {
        if (copyRef.current) {
            const innerText = copyRef.current.innerText ? copyRef.current.innerText.replace(/\n/gi, ' ') : '';

            setDescriptionText(innerText || '');
        }
    }, []);

    useEffect(() => {
        if (!overlayElem) {
            setOverlayElem(document.getElementById('new-feature-overlay'));
        }
    }, [overlayElem]);

    useEffect(() => {
        // Show page overlay if tooltip is shown
        if (!hideOverlay && showTooltipState) {
            overlayElem?.classList.add(...NEW_FEATURE_OVERLAY_CLASSNAMES, featureKey);
            document.body.classList.add('no-scroll');
        }
    }, [featureKey, hideOverlay, overlayElem?.classList, showTooltipState]);

    useEffect(() => {
        // Remove overlay if tooltip has been viewed
        if (!hideOverlay && hideTooltipState) {
            showTooltipState && hideTooltip();

            overlayElem?.classList.remove(...NEW_FEATURE_OVERLAY_CLASSNAMES, featureKey);
            document.body.classList.remove('no-scroll');
        }
    }, [featureKey, hideOverlay, hideTooltip, hideTooltipState, overlayElem?.classList, showTooltipState]);

    return (
        <div className={cn('relative', className)}>
            <div
                className={cn(
                    'bg-white border border-analyst-blue rounded-lg p-4 shadow-md flex flex-col gap-4 absolute w-full opacity-0 transition-opacity -z-10 min-h-min',
                    tooltipPositionMap[tooltipPosition],
                    {
                        'opacity-100 transition-opacity z-200': showTooltipState,
                    },
                    tooltipClassName
                )}
            >
                <header className="flex items-center justify-between">
                    <span className="bg-analyst-blue text-white font-brand-md text-xs uppercase rounded-full px-3 py-2 text-center tracking-wider">
                        {tagCopy}
                    </span>

                    <IconButton
                        iconType="closeX"
                        onClick={hideTooltip}
                        iconProps={{
                            size: 'sm',
                        }}
                        tracking={{
                            eventType: eventTypes.CLOSE_NEW_FEATURE_TOOLTIP,
                            trackingProperties: {
                                component: components.NEW_FEATURE_TOOLTIP,
                                description: descriptionText,
                                featureType: featureKey,
                            } as NewFeatureProperties,
                        }}
                    />
                </header>

                <div
                    ref={copyRef}
                    className={cn('flex flex-col gap-2', descriptionClassName)}
                >
                    {featureDescription.map((description, index: number) => (
                        <Paragraph
                            key={`new-feature-description-copy-${index}`}
                            className="m-0 text-black font-brand-md text-sm"
                        >
                            {description}
                        </Paragraph>
                    ))}
                </div>
            </div>

            {children}
        </div>
    );
};
