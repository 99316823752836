import { gql } from 'graphql-request';

import type { InstrumentFullNewsEvaluation } from '@/queries/graphql-types';
import { CompanyModuleTypes, CompanyValuations } from '@/types/company';
import { FundamentalsType } from '@/types/fundamentals';
import { InstrumentType } from '@/types/instrument';
import { Nullable } from '@/types/nullable';

export interface SearchInstrumentInput {
    companyEnabled?: boolean;
    cryptoEnabled?: boolean;
    etfEnabled?: boolean;
    searchValue: string;
    limit?: number;
    businessFundamentalFieldNames?: Nullable<Array<string>>;
    tradingFundamentalFieldNames?: Nullable<Array<string>>;
    minAsOfDate?: string;
    maxAsOfDate?: string;
}

export type FilterSecuritiesInput = SearchInstrumentInput & {
    recommendSimilarInIndexBuilder?: boolean;
    sectors?: Nullable<Array<string>>;
    industries?: Nullable<Array<string>>;
    countries?: Nullable<Array<string>>;
    definedFundamentals?: FundamentalsType;
};

export interface TableDataFrame {
    columns: Array<string>;
    index: Array<string>;
    rows: Array<{ [key: string]: string | number }>;
}

export type PeersType = InstrumentType;
export interface PriceHistoryType {
    type: string;
    date: string;
    analysis: string;
}

export interface EvaluationType {
    name?: string;
    insights?: string;
    summary?: string;
    link?: string;
}

export interface PeerFundamentalsType {
    date?: string;
    analysis?: string;
}

export interface PeerDataType {
    peers?: Array<PeersType>;
    peerPriceHistory?: PriceHistoryType;
    peerFundamentals?: PeerFundamentalsType;
}

export interface FundamentalsEvaluationType {
    quarterlyFinancials: {
        incomeStatement: TableDataFrame;
        balanceSheet: TableDataFrame;
        cashFlowStatement: TableDataFrame;
    };
    insights?: string;
    analysis?: string;
}

export type EvaluationTypeWithDate = EvaluationType & { date?: Date };

export type PeersBenchmarksData = PeerDataType & {
    benchmarks?: Array<InstrumentType>;
    benchmarkPriceHistory?: PriceHistoryType;
};

export type PartialEvalutionType = PeerDataType;
export interface InstrumentEvaluationType {
    instrumentEvaluationResult: {
        insights?: string;
        analysis?: string;
        date: Date;
        isCompleted: boolean;
        priceHistoryStart?: string;
        priceHistoryEnd?: string;
        fundamentalsAndRatios?: TableDataFrame;
        instrument: InstrumentType;
        newsEvaluation?: Nullable<InstrumentFullNewsEvaluation>;
        benchmarks: Array<InstrumentType>;
        benchmarkPriceHistory: PriceHistoryType;
        peers: Array<PeersType>;
        peerPriceHistory: PriceHistoryType;
        peerFundamentals: PeerFundamentalsType;
        fundamentalsEvaluation: FundamentalsEvaluationType;
        earningsCallEvaluation: EvaluationTypeWithDate;
        secFilingEvaluation: EvaluationType;
        valuation: CompanyValuations;
        valuationReasoning?: string;
    };
}

export type InstrumentEvaluationResult = InstrumentEvaluationType['instrumentEvaluationResult'];

export interface GetInstrumentEvaluationArgs {
    instrumentId: string;
    requestId?: string | Array<string>;
    organizationId?: string;
    businessFundamentalFieldNames?: Nullable<Array<string>>;
    tradingFundamentalFieldNames?: Nullable<Array<string>>;
}

export interface GetInstrumentEvaluationResponse {
    evaluation: InstrumentEvaluationResult;
    requestId: string;
}

export const COMPS_QUERY = gql`
    query GetCompInstruments($ids: [ID!]!) {
        instruments(ids: $ids) {
            id
            companyName
            symbol
            exchange
            instrumentType
        }
    }
`;

export const GET_INSTRUMENT_BY_ID_QUERY = gql`
    query getInstrument(
        $id: ID!
        $minAsOfDate: Date!
        $maxAsOfDate: Date!
        $businessFundamentalFieldNames: [String!]!
        $tradingFundamentalFieldNames: [String!]!
    ) {
        instrument(id: $id) {
            __typename
            id
            createdAt
            symbol
            exchange
            companyName
            instrumentType
            primaryRegionCountryCode
            primaryRegion
            latestFundamentals {
                companyName
                sectorName
                industryName
                city
                state
                businessDescription
                grossIncomeMargin
                marketCapitalizationUsd
                oneYearAnnualRevenueGrowthRate
                enterpriseValueRevenueRatio
                netRevenueRetention
                ebitdaMargin
            }
            articles {
                id
                name
                readableSource
                preview
                citationLink
            }
            similarInstruments {
                id
                symbol
                companyName
            }
            adjustedPriceValues(minAsOfDate: $minAsOfDate, maxAsOfDate: $maxAsOfDate) {
                asOfDate
                value
            }
            businessFundamentals: fundamentals(fieldNames: $businessFundamentalFieldNames) {
                name
                __typename
                ... on InstrumentFundamentalDate {
                    dateValue
                }
                ... on InstrumentFundamentalDecimal {
                    decimalValue
                }
                ... on InstrumentFundamentalString {
                    stringValue
                }
            }
            tradingFundamentals: fundamentals(fieldNames: $tradingFundamentalFieldNames) {
                name
                __typename
                ... on InstrumentFundamentalDate {
                    dateValue
                }
                ... on InstrumentFundamentalDecimal {
                    decimalValue
                }
                ... on InstrumentFundamentalString {
                    stringValue
                }
            }
        }
    }
`;

export const GET_INSTRUMENT_BY_TICKER_EXCHANGE_QUERY = gql`
    query getInstrumentByTickerExchange($ticker: String!, $exchange: String) {
        instrumentLookupBySymbol(input: { symbol: $ticker, exchange: $exchange }) {
            __typename
            id
            companyName
            exchange
            symbol
        }
    }
`;
export const SEARCH_INSTRUMENTS_QUERY = gql`
    query searchInstruments(
        $filter: InstrumentSearchFilterInput
        $limit: Int
        $minAsOfDate: Date!
        $maxAsOfDate: Date!
        $businessFundamentalFieldNames: [String!]!
        $tradingFundamentalFieldNames: [String!]!
    ) {
        instrumentSearch(input: { filter: $filter, limit: $limit }) {
            instruments {
                id
                createdAt
                symbol
                exchange
                companyName
                instrumentType
                primaryRegionCountryCode
                primaryRegion
                latestFundamentals {
                    companyName
                    sectorName
                    industryName
                    city
                    state
                    businessDescription
                    grossIncomeMargin
                    marketCapitalizationUsd
                    oneYearAnnualRevenueGrowthRate
                    enterpriseValueRevenueRatio
                    netRevenueRetention
                    ebitdaMargin
                }
                articles {
                    id
                    name
                    readableSource
                    preview
                    citationLink
                }
                similarInstruments {
                    id
                    symbol
                    companyName
                }
                adjustedPriceValues(minAsOfDate: $minAsOfDate, maxAsOfDate: $maxAsOfDate) {
                    asOfDate
                    value
                }
                businessFundamentals: fundamentals(fieldNames: $businessFundamentalFieldNames) {
                    name
                    __typename
                    ... on InstrumentFundamentalDate {
                        dateValue
                    }
                    ... on InstrumentFundamentalDecimal {
                        decimalValue
                    }
                    ... on InstrumentFundamentalString {
                        stringValue
                    }
                }
                tradingFundamentals: fundamentals(fieldNames: $tradingFundamentalFieldNames) {
                    name
                    __typename
                    ... on InstrumentFundamentalDate {
                        dateValue
                    }
                    ... on InstrumentFundamentalDecimal {
                        decimalValue
                    }
                    ... on InstrumentFundamentalString {
                        stringValue
                    }
                }
            }
        }
    }
`;

export const GET_INSTRUMENT_FUNDAMENTALS_QUERY = gql`
    query GetInstruments($ids: [ID!]!, $fieldNames: [String!]!) {
        instruments(ids: $ids) {
            id
            fundamentals(fieldNames: $fieldNames) {
                name
                __typename
                ... on InstrumentFundamentalDate {
                    dateValue
                }
                ... on InstrumentFundamentalDecimal {
                    decimalValue
                }
                ... on InstrumentFundamentalString {
                    stringValue
                }
            }
        }
    }
`;

export const PARTIAL_COMPANY_FRAGMENT_NAMES: {
    [key in CompanyModuleTypes]?: string;
} = {
    [CompanyModuleTypes.PEERS]: gql`CompanyPeersFragment`,
    [CompanyModuleTypes.PEER_FUNDAMENTALS]: gql`CompanyPeerFundamentalsFragment`,
    [CompanyModuleTypes.NEWS]: gql`CompanyNewsFragment`,
    [CompanyModuleTypes.PRICE_HISTORY]: gql`CompanyPriceHistoryFragment`,
    [CompanyModuleTypes.COMPANY_EVALUATION_ANALYSIS]: gql`CompanyAnalysisFragment`,
    [CompanyModuleTypes.FINANCIALS]: gql`CompanyFinancialsFragment`,
    [CompanyModuleTypes.FILINGS]: gql`CompanyFilingsFragment`,
    [CompanyModuleTypes.EARNINGS_CALLS]: gql`CompanyEarningsCallsFragment`,
    [CompanyModuleTypes.FUNDAMENTALS]: gql`CompanyFundamentalsFragment`,
    [CompanyModuleTypes.INSIGHTS]: gql`CompanyInsightsFragment`,
    [CompanyModuleTypes.VALUATION]: gql`CompanyValuationFragment`,
};
const COMPANY_INSTRUMENT_FRAGMENT_NAME = gql`CompanyInstrumentFragment`;
export const COMPANY_PEERS_QUERY_FRAGMENT = gql`
    fragment ${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.PEERS]} on InstrumentEvaluation {
        benchmarks {
            id
            symbol
            exchange
            exchangeName
            companyName
            instrumentType
            latestFundamentals {
                companyName
                sectorName
                industryName
                businessDescription
            }
        }
        benchmarkPriceHistory {
            type
            date
            analysis
        }
        peers {
            id
            symbol
            exchange
            exchangeName
            companyName
            instrumentType
            latestFundamentals {
                companyName
                sectorName
                industryName
                businessDescription
            }
        }
        peerPriceHistory {
            type
            date
            analysis
        }
        peerFundamentals {
            date
            analysis
        }
    }
`;
export const COMPANY_FUNDAMENTALS_QUERY_FRAGMENT = gql`
    fragment ${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.FUNDAMENTALS]} on InstrumentEvaluation {
        fundamentalsEvaluation {
            quarterlyFinancials {
                incomeStatement {
                    columns
                    index
                    rows
                }
                balanceSheet {
                    columns
                    index
                    rows
                }
                cashFlowStatement {
                    columns
                    index
                    rows
                }
            }
            insights
            analysis
        }
    }
`;
export const COMPANY_INSIGHTS_QUERY_FRAGMENT = gql`
    fragment ${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.INSIGHTS]} on InstrumentEvaluation {
        insights
    }
`;
export const COMPANY_EARNINGS_CALLS_QUERY_FRAGMENT = gql`
    fragment ${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.EARNINGS_CALLS]} on InstrumentEvaluation {
        earningsCallEvaluation {
            name
            date
            insights
            managementFocus
            analystsFocus
            summary
        }
    }
`;
export const COMPANY_FILINGS_QUERY_FRAGMENT = gql`
    fragment ${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.FILINGS]} on InstrumentEvaluation {
        secFilingEvaluation {
            name
            insights
            summary
            link
        }
    }
`;
export const COMPANY_FINANCIALS_QUERY_FRAGMENT = gql`
    fragment ${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.FINANCIALS]} on InstrumentEvaluation {
        fundamentalsEvaluation {
            quarterlyFinancials {
                incomeStatement {
                    columns
                    index
                    rows
                }
                balanceSheet {
                    columns
                    index
                    rows
                }
                cashFlowStatement {
                    columns
                    index
                    rows
                }
            }
        }
    }
`;
export const COMPANY_EVALUATION_ANALYSIS_QUERY_FRAGMENT = gql`
    fragment ${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.COMPANY_EVALUATION_ANALYSIS]} on InstrumentEvaluation {
        analysis
    }
`;
export const COMPANY_PRICE_HISTORY_QUERY_FRAGMENT = gql`
    fragment ${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.PRICE_HISTORY]} on InstrumentEvaluation {
        priceHistoryStart
        priceHistoryEnd
        benchmarks {
            id
            symbol
            instrumentType
        }
    }
`;
export const COMPANY_PEER_FUNDAMENTALS_QUERY_FRAGMENT = gql`
    fragment ${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.PEER_FUNDAMENTALS]} on InstrumentEvaluation {
        fundamentalsAndRatios {
            columns
            index
            rows
        }
    }
`;

const NEWS_ITEM_FRAGMENT_NAME = gql`NewsItemFragment`;
export const NEWS_ITEM_QUERY_FRAGMENT = gql`
    fragment ${NEWS_ITEM_FRAGMENT_NAME} on NewsItem {
        text
        articles {
            title
            url
            image
            publishedAt
            site
        }
    }
`;
export const COMPANY_NEWS_QUERY_FRAGMENT = gql`
    ${NEWS_ITEM_QUERY_FRAGMENT}
    fragment ${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.NEWS]} on InstrumentEvaluation {
        newsEvaluation {
            id
            createdAt
            date
            sentiment
            sentimentRating
            riskFactors {
                ...${NEWS_ITEM_FRAGMENT_NAME}
            }
            keyTakeaways {
                ...${NEWS_ITEM_FRAGMENT_NAME}
            }
            news {
                ...${NEWS_ITEM_FRAGMENT_NAME}
            }
            analysis {
                ...${NEWS_ITEM_FRAGMENT_NAME}
            }
            trends {
                ...${NEWS_ITEM_FRAGMENT_NAME}
            }
        }
    }
`;
export const COMPANY_INSTRUMENT_QUERY_FRAGMENT = gql`
    fragment ${COMPANY_INSTRUMENT_FRAGMENT_NAME} on InstrumentEvaluation {
        instrument {
            id
            symbol
            exchange
            companyName
            instrumentType
            primaryRegionCountryCode
            latestFundamentals {
                companyName
                sectorName
                industryName
                city
                state
                businessDescription
                grossIncomeMargin
                marketCapitalizationUsd
                oneYearAnnualRevenueGrowthRate
                enterpriseValueRevenueRatio
                netRevenueRetention
                ebitdaMargin
            }
            articles {
                id
                name
                readableSource
                preview
                citationLink
            }
            similarInstruments {
                id
                symbol
                companyName
            }
            adjustedPriceValues(minAsOfDate: $minAsOfDate, maxAsOfDate: $maxAsOfDate) {
                asOfDate
                value
            }
            businessFundamentals: fundamentals(fieldNames: $businessFundamentalFieldNames) {
                name
                __typename
                ... on InstrumentFundamentalDate {
                    dateValue
                }
                ... on InstrumentFundamentalDecimal {
                    decimalValue
                }
                ... on InstrumentFundamentalString {
                    stringValue
                }
            }
            tradingFundamentals: fundamentals(fieldNames: $tradingFundamentalFieldNames) {
                name
                __typename
                ... on InstrumentFundamentalDate {
                    dateValue
                }
                ... on InstrumentFundamentalDecimal {
                    decimalValue
                }
                ... on InstrumentFundamentalString {
                    stringValue
                }
            }
        }
    }
`;
export const COMPANY_VALUATION_QUERY_FRAGMENT = gql`
    fragment ${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.VALUATION]} on InstrumentEvaluation {
        valuation
        valuationReasoning
    }
`;

export const PARTIAL_COMPANY_FRAGMENTS: {
    [key in CompanyModuleTypes]?: {
        name?: string;
        fragment: string;
    };
} = {
    [CompanyModuleTypes.PEERS]: {
        fragment: COMPANY_PEERS_QUERY_FRAGMENT,
        name: PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.PEERS],
    },
    [CompanyModuleTypes.NEWS]: {
        fragment: COMPANY_NEWS_QUERY_FRAGMENT,
        name: PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.NEWS],
    },
    [CompanyModuleTypes.PRICE_HISTORY]: {
        fragment: COMPANY_PRICE_HISTORY_QUERY_FRAGMENT,
        name: PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.PRICE_HISTORY],
    },
    [CompanyModuleTypes.COMPANY_EVALUATION_ANALYSIS]: {
        fragment: COMPANY_EVALUATION_ANALYSIS_QUERY_FRAGMENT,
        name: PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.COMPANY_EVALUATION_ANALYSIS],
    },
    [CompanyModuleTypes.FINANCIALS]: {
        fragment: COMPANY_FINANCIALS_QUERY_FRAGMENT,
        name: PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.FINANCIALS],
    },
    [CompanyModuleTypes.FILINGS]: {
        fragment: COMPANY_FILINGS_QUERY_FRAGMENT,
        name: PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.FILINGS],
    },
    [CompanyModuleTypes.EARNINGS_CALLS]: {
        fragment: COMPANY_EARNINGS_CALLS_QUERY_FRAGMENT,
        name: PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.EARNINGS_CALLS],
    },
    [CompanyModuleTypes.FUNDAMENTALS]: {
        fragment: COMPANY_FUNDAMENTALS_QUERY_FRAGMENT,
        name: PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.FUNDAMENTALS],
    },
    [CompanyModuleTypes.INSIGHTS]: {
        fragment: COMPANY_INSIGHTS_QUERY_FRAGMENT,
        name: PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.INSIGHTS],
    },
    [CompanyModuleTypes.VALUATION]: {
        fragment: COMPANY_VALUATION_QUERY_FRAGMENT,
        name: PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.VALUATION],
    },
    [CompanyModuleTypes.PEER_FUNDAMENTALS]: {
        fragment: COMPANY_PEER_FUNDAMENTALS_QUERY_FRAGMENT,
        name: PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.PEER_FUNDAMENTALS],
    },
};

export const INSTRUMENT_EVALUATION_REQUEST_QUERY = gql`
    query instrumentEvalutionRequestQuery($instrumentId: ID!, $organizationId: ID = null) {
        instrumentEvaluationRequest(instrumentId: $instrumentId, organizationId: $organizationId)
    }
`;

export const INSTRUMENT_EVALUATION_RESULTS_QUERY = gql`
    ${COMPANY_PEERS_QUERY_FRAGMENT}
    ${COMPANY_FUNDAMENTALS_QUERY_FRAGMENT}
    ${COMPANY_INSIGHTS_QUERY_FRAGMENT}
    ${COMPANY_EARNINGS_CALLS_QUERY_FRAGMENT}
    ${COMPANY_FILINGS_QUERY_FRAGMENT}
    ${COMPANY_FINANCIALS_QUERY_FRAGMENT}
    ${COMPANY_EVALUATION_ANALYSIS_QUERY_FRAGMENT}
    ${COMPANY_PRICE_HISTORY_QUERY_FRAGMENT}
    ${COMPANY_NEWS_QUERY_FRAGMENT}
    ${COMPANY_INSTRUMENT_QUERY_FRAGMENT}
    ${COMPANY_PEER_FUNDAMENTALS_QUERY_FRAGMENT}
    ${COMPANY_VALUATION_QUERY_FRAGMENT}
    query instrumentEvalutionResultsQuery(
        $requestId: ID!
        $minAsOfDate: Date!
        $maxAsOfDate: Date!
        $businessFundamentalFieldNames: [String!]!
        $tradingFundamentalFieldNames: [String!]!
    ) {
        instrumentEvaluationResult(requestId: $requestId) {
            date
            isCompleted
            ...${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.PEERS]}
            ...${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.NEWS]}
            ...${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.PRICE_HISTORY]}
            ...${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.COMPANY_EVALUATION_ANALYSIS]}
            ...${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.FINANCIALS]}
            ...${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.FILINGS]}
            ...${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.EARNINGS_CALLS]}
            ...${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.FUNDAMENTALS]}
            ...${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.PEER_FUNDAMENTALS]}
            ...${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.INSIGHTS]}
            ...${PARTIAL_COMPANY_FRAGMENT_NAMES[CompanyModuleTypes.VALUATION]}
            ...${COMPANY_INSTRUMENT_FRAGMENT_NAME}

        }
    }
`;

export const INSTRUMENT_ANALYSIS_QUERY = gql`
    query instrumentAnalysis($instrumentId: ID!, $userQuery: String!) {
        instrumentAnalysis(instrumentId: $instrumentId, userQuery: $userQuery) {
            id
            analysis
            finishedAt
            state
            isCompleted
            excerpts {
                article {
                    name
                    citationLink
                    readableSource
                }
                sentences {
                    sentenceEmbeddingId
                    htmlText
                    isHighlighted
                }
            }
        }
    }
`;

export const INSTRUMENT_EVALUATION_REQUESTS_REMAINING = gql`
    query InstrumentEvaluationRequestsRemaining {
        instrumentEvaluationRequestsRemaining
    }
`;

export const INSTRUMENT_EVALUATION_REQUEST_COUNT = gql`
    query InstrumentEvaluationRequestCount {
        instrumentEvaluationRequestCount
    }
`;

export const ALLOW_INSTRUMENT_EVALUATION_REQUEST = gql`
    query AllowInstrumentEvaluationRequest {
        allowInstrumentEvaluationRequest
    }
`;

export const SET_INSTRUMENT_COMPS_MUTATION = gql`
    mutation updateInstrumentComps($input: SetInstrumentCompsRequest!) {
        setInstrumentEvaluationComps(request: $input) {
            __typename
            success
        }
    }
`;
