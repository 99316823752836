import { gql } from 'graphql-request';

import { INDEX_CARD_FRAGMENT, INDEX_CARD_FRAGMENT_NAME } from '@/queries/index-card';
import { SNAPSHOT_FOR_HOLDINGS_FRAGMENT, SNAPSHOT_FOR_HOLDINGS_FRAGMENT_NAME } from '@/queries/snapshot';

export const INDEX_BASIC_INFO_QUERY = gql`
    query getIndex($symbol: String!) {
        index(symbol: $symbol) {
            __typename
            id
            name
            symbol
        }
    }
`;

export const INDEX_QUERY = gql`
    ${INDEX_CARD_FRAGMENT}
    ${SNAPSHOT_FOR_HOLDINGS_FRAGMENT}
    query getIndex($symbol: String!, $threeMonthsAgo: Date!) {
        index(symbol: $symbol) {
            id
            name
            methodologyBlogSlug
            firstEffectiveAt
            isHidden
            isLocked
            isPortfolio
            isComponentsPrivate
            canDuplicate
            similarIndexes {
                ...${INDEX_CARD_FRAGMENT_NAME}
            }
            dailySnapshots {
                asOfDate
                netAssetValue
                benchmarks {
                    level
                    symbol
                }
                isBackcalculated
            }
            dailySnapshotsLessCosts {
                asOfDate
                netAssetValue
                isBackcalculated
            }
            benchmarkIndexes {
                symbol
                name
            }
            symbol
            aboutUrl
            bannerUrl
            annualCostRate
            organization {
                id
                name
                twitterHandle
                avatarUrl
                description
                aboutUrl
            }
            latestSnapshot {
                ...${SNAPSHOT_FOR_HOLDINGS_FRAGMENT_NAME}
            }
            displayedFundamentalsColumns
            longDescription
            description
            activeVersion {
                strategy: strategyIfNotPrivate {
                    __typename
                }
            }
            recentUpdateMessage
        }
    }
`;

export const MUTATION_UPDATE_INDEX_OPENGRAPH_IMAGE = gql`
    mutation ($imageFile: Upload, $indexId: ID!) {
        uploadIndexOpengraphImage(input: { imageFile: $imageFile, indexId: $indexId }) {
            __typename
            ... on Error {
                message
            }
            ... on Index {
                opengraphUrl
            }
        }
    }
`;
