import {
    ANALYSIS_TYPE_QUERY_PARAM_KEY,
    ANALYST_PATH,
    ANALYST_REQUEST_ID_QUERY_PARAM_KEY,
    INSTRUMENT_ANALYSIS_TYPE_QUERY_PARAM_KEY,
    PROMPT_QUERY_PARAM_KEY,
    RESEARCH_ANALYSIS_TYPE_QUERY_PARAM_KEY,
} from '@/lib/constants/analyst';

export const constructResearchAnalysisPromptUrl = (prompt: string, requestId?: string) => {
    const url = `${ANALYST_PATH}?${ANALYSIS_TYPE_QUERY_PARAM_KEY}=${encodeURIComponent(
        RESEARCH_ANALYSIS_TYPE_QUERY_PARAM_KEY
    )}`;

    if (requestId) {
        return `${url}&${ANALYST_REQUEST_ID_QUERY_PARAM_KEY}=${requestId}`;
    }

    return `${url}&${PROMPT_QUERY_PARAM_KEY}=${encodeURIComponent(prompt)}`;
};

export const constructInstrumentAnalysisPromptUrl = (prompt: string, requestId?: string) => {
    const url = `${ANALYST_PATH}?${ANALYSIS_TYPE_QUERY_PARAM_KEY}=${encodeURIComponent(
        INSTRUMENT_ANALYSIS_TYPE_QUERY_PARAM_KEY
    )}}`;

    if (requestId) {
        return `${url}&${ANALYST_REQUEST_ID_QUERY_PARAM_KEY}=${requestId}`;
    }

    return `${url}&${PROMPT_QUERY_PARAM_KEY}=${encodeURIComponent(prompt)}`;
};
