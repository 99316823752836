import { TeamViews } from '@/lib/constants/team';

interface ConstructTeamPageUrlArgs {
    organizationId: string;
    teamView?: TeamViews;
}

export const constructTeamPageUrl = ({ organizationId, teamView }: ConstructTeamPageUrlArgs) => {
    const basePath = `/team/${organizationId}`;
    return teamView ? `${basePath}/${teamView}` : basePath;
};
