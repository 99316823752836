import { subDays } from 'date-fns';
import { ClientError } from 'graphql-request';
import { gql } from 'graphql-request';

import { FormatUtcDate } from '@/helpers/dates';
import { BuildFundamentalInputDefaults } from '@/helpers/fundamentals';
import { multiplierFromScale } from '@/helpers/numbers';
import { BUSINESS_FUNDAMENTALS, TRADING_FUNDAMENTALS } from '@/lib/constants';
import { GQL_CLIENT } from '@/lib/graphql';
import type { SetInstrumentCompsRequest, SetInstrumentCompsResult } from '@/queries/graphql-types';
import {
    ALLOW_INSTRUMENT_EVALUATION_REQUEST,
    FilterSecuritiesInput,
    GET_INSTRUMENT_BY_ID_QUERY,
    GET_INSTRUMENT_BY_TICKER_EXCHANGE_QUERY,
    GET_INSTRUMENT_FUNDAMENTALS_QUERY,
    type GetInstrumentEvaluationArgs,
    type GetInstrumentEvaluationResponse,
    INSTRUMENT_EVALUATION_REQUEST_COUNT,
    INSTRUMENT_EVALUATION_REQUEST_QUERY,
    INSTRUMENT_EVALUATION_REQUESTS_REMAINING,
    INSTRUMENT_EVALUATION_RESULTS_QUERY,
    InstrumentEvaluationResult,
    InstrumentEvaluationType,
    PARTIAL_COMPANY_FRAGMENTS,
    SEARCH_INSTRUMENTS_QUERY,
    SearchInstrumentInput,
    SET_INSTRUMENT_COMPS_MUTATION,
} from '@/queries/instruments';
import { ApiError } from '@/types/api';
import { CompanyModuleTypes } from '@/types/company';
import { FundamentalField, FundamentalModifier } from '@/types/fundamentals';
import { InstrumentSearchResultType } from '@/types/index';
import { FundamentalType, InstrumentType } from '@/types/instrument';
import { Nullable } from '@/types/nullable';
import { InstrumentUrlParams } from '@/types/page';

const BIG_COUNTRIES = ['US', 'CN', 'JP', 'KR']; // Have 2k-6k instruments each

export const filterSecurities = async ({
    companyEnabled = true,
    cryptoEnabled = false,
    etfEnabled = false,
    recommendSimilarInIndexBuilder = false,
    sectors,
    industries,
    countries,
    definedFundamentals = BuildFundamentalInputDefaults(),
    searchValue,
    limit = 100,
}: FilterSecuritiesInput): Promise<{ instruments: Array<InstrumentSearchResultType> }> => {
    const fundamentals: Array<object> = [];
    let keywordFilter: object | undefined = undefined;

    // build the fundamentals section
    for (const fieldKey in definedFundamentals) {
        const field = definedFundamentals[fieldKey as FundamentalField];
        for (const key in field) {
            const compareKey = key as FundamentalModifier;
            const value = field[compareKey];
            if ([FundamentalModifier.GT, FundamentalModifier.LT].indexOf(compareKey) !== -1 && value) {
                const multiplier =
                    compareKey === FundamentalModifier.GT
                        ? multiplierFromScale(field[FundamentalModifier.gtScale])
                        : multiplierFromScale(field[FundamentalModifier.ltScale]);
                if (fieldKey && compareKey && value) {
                    fundamentals.push({
                        comparison: {
                            comparator: compareKey,
                            field: fieldKey,
                            value: (value as number) * multiplier,
                        },
                    });
                }
            }
        }
    }

    const FULL_MATCH_KEYWORD_QUERY_FIELDS = ['SYMBOL', 'BUSINESS_DESCRIPTION', 'INDUSTRY_NAME', 'SECTOR_NAME', 'CEO'];
    const PARTIAL_MATCH_KEYWORD_QUERY_FIELDS = ['COMPANY_NAME'];

    if (searchValue.trim()) {
        // Split search into terms by commas. TODO: add a UI hint to this behavior, and/or choose a more obvious separator
        const searchKeywordTerms = searchValue.split(',').map((x) => x.trim());
        keywordFilter = {
            or: {
                filters: searchKeywordTerms.map((term) => {
                    return {
                        or: {
                            filters: [
                                {
                                    combinedQuery: {
                                        fields: FULL_MATCH_KEYWORD_QUERY_FIELDS,
                                        query: term.trim(),
                                    },
                                },
                                {
                                    combinedQuery: {
                                        elasticsearchType: 'PHRASE_PREFIX',
                                        fields: PARTIAL_MATCH_KEYWORD_QUERY_FIELDS,
                                        query: term.trim(),
                                    },
                                },
                            ],
                        },
                    };
                }),
            },
        };
    }

    if (
        !sectors &&
        !industries &&
        // We have so many instruments for US/CN/JP/KR that we don't want to show them all if they are the only filter
        (!countries || countries?.some((c) => BIG_COUNTRIES.includes(c))) &&
        !fundamentals.length &&
        !keywordFilter
    ) {
        return { instruments: [] };
    }

    const filters = [];

    if (keywordFilter) {
        filters.push(keywordFilter);
    }

    if (sectors) {
        filters.push({
            in: { field: 'SECTOR_NAME', values: sectors },
        });
    }

    if (industries) {
        filters.push({
            in: { field: 'INDUSTRY_NAME', values: industries },
        });
    }

    if (countries) {
        filters.push({
            in: { field: 'PRIMARY_REGION_COUNTRY_CODE', values: countries },
        });
    }

    const instrumentTypes = [];
    if (companyEnabled) {
        instrumentTypes.push('COMPANY_STOCK');
    }
    if (cryptoEnabled) {
        instrumentTypes.push('CRYPTO');
    }
    if (etfEnabled) {
        instrumentTypes.push('ETF');
    }
    filters.push({
        in: { field: 'INSTRUMENT_TYPE', values: instrumentTypes },
    });

    if (fundamentals.length) {
        filters.push(...fundamentals);
    }

    const variables = { filter: { and: { filters } }, limit };

    const similarInstrumentsFragment = gql`
        fragment similarInstrumentsFragment on Instrument {
            similarInstruments {
                symbol
                id
                companyName
                instrumentType
                latestFundamentals {
                    businessDescription
                    grossIncomeMargin
                    marketCapitalizationUsd
                    oneYearAnnualRevenueGrowthRate
                    enterpriseValueRevenueRatio
                    netRevenueRetention
                    ebitdaMargin
                }
            }
        }
    `;

    const query = gql`
        query FilterSecurities($filter: InstrumentSearchFilterInput, $limit: Int) {
            instrumentSearch(input: { filter: $filter, limit: $limit }) {
                instruments {
                    symbol
                    id
                    exchange
                    companyName
                    instrumentType
                    latestFundamentals {
                        businessDescription
                        grossIncomeMargin
                        marketCapitalizationUsd
                        oneYearAnnualRevenueGrowthRate
                        enterpriseValueRevenueRatio
                        netRevenueRetention
                        ebitdaMargin
                    }
                    ${recommendSimilarInIndexBuilder ? '...similarInstrumentsFragment' : ''}
                }
            }
        }
        ${recommendSimilarInIndexBuilder ? similarInstrumentsFragment : ''}
    `;

    const response: { instrumentSearch: { instruments: Array<InstrumentSearchResultType> } } = await GQL_CLIENT.request(
        query,
        variables
    );

    return response.instrumentSearch;
};

export const searchInstruments = async ({
    companyEnabled = true,
    cryptoEnabled = false,
    etfEnabled = false,
    businessFundamentalFieldNames = BUSINESS_FUNDAMENTALS,
    tradingFundamentalFieldNames = TRADING_FUNDAMENTALS,
    searchValue,
    minAsOfDate,
    maxAsOfDate,
}: SearchInstrumentInput): Promise<{ instruments: Array<InstrumentType> }> => {
    let keywordFilter: object | undefined = undefined;
    const FULL_MATCH_KEYWORD_QUERY_FIELDS = ['SYMBOL'];
    const PARTIAL_MATCH_KEYWORD_QUERY_FIELDS = ['COMPANY_NAME'];

    if (searchValue.trim()) {
        // Split search into terms by commas. TODO: add a UI hint to this behavior, and/or choose a more obvious separator
        const searchKeywordTerms = searchValue.split(',').map((x) => x.trim());
        keywordFilter = {
            or: {
                filters: searchKeywordTerms.map((term) => {
                    return {
                        or: {
                            filters: [
                                {
                                    combinedQuery: {
                                        fields: FULL_MATCH_KEYWORD_QUERY_FIELDS,
                                        query: term.trim(),
                                    },
                                },
                                {
                                    combinedQuery: {
                                        elasticsearchType: 'PHRASE_PREFIX',
                                        fields: PARTIAL_MATCH_KEYWORD_QUERY_FIELDS,
                                        query: term.trim(),
                                    },
                                },
                            ],
                        },
                    };
                }),
            },
        };
    }

    const filters = [];

    if (keywordFilter) {
        filters.push(keywordFilter);
    }

    const instrumentTypes = [];
    if (companyEnabled) {
        instrumentTypes.push('COMPANY_STOCK');
    }
    if (cryptoEnabled) {
        instrumentTypes.push('CRYPTO');
    }
    if (etfEnabled) {
        instrumentTypes.push('ETF');
    }

    filters.push({
        in: { field: 'INSTRUMENT_TYPE', values: instrumentTypes },
    });

    const variables = {
        businessFundamentalFieldNames,
        filter: { and: { filters } },
        maxAsOfDate,
        minAsOfDate,
        tradingFundamentalFieldNames,
    };

    const response: { instrumentSearch: { instruments: Array<InstrumentType> } } = await GQL_CLIENT.request(
        SEARCH_INSTRUMENTS_QUERY,
        variables
    );

    return response.instrumentSearch;
};

export const getMatchingInstrument = async ({
    ticker,
    exchange,
}: InstrumentUrlParams): Promise<InstrumentType | ApiError> => {
    const {
        instrumentLookupBySymbol: [matchingInstrument],
    } = await GQL_CLIENT.request(GET_INSTRUMENT_BY_TICKER_EXCHANGE_QUERY, {
        exchange,
        ticker,
    });

    if (!matchingInstrument) {
        return { error: 'No matching instrument found' } as ApiError;
    }

    // If found matching instrument, fetch all data needed for the page.
    const today = FormatUtcDate(new Date(), 'yyyy-MM-dd');
    const oneWeekAgo = FormatUtcDate(subDays(new Date(), 7), 'yyyy-MM-dd');
    const { instrument } = await GQL_CLIENT.request(GET_INSTRUMENT_BY_ID_QUERY, {
        businessFundamentalFieldNames: BUSINESS_FUNDAMENTALS,
        id: matchingInstrument.id,
        maxAsOfDate: today,
        minAsOfDate: oneWeekAgo,
        tradingFundamentalFieldNames: TRADING_FUNDAMENTALS,
    });

    return instrument;
};

export const getInstrumentEvaluationRequest = async ({
    instrumentId,
    organizationId,
}: {
    instrumentId: string;
    organizationId?: string;
}): Promise<string | ClientError> => {
    try {
        const evaluationRequest = await GQL_CLIENT.request(INSTRUMENT_EVALUATION_REQUEST_QUERY, {
            instrumentId,
            organizationId,
        });

        return evaluationRequest.instrumentEvaluationRequest;
    } catch (error) {
        return error as ClientError;
    }
};

export const getInstrumentEvaluationResponse = async ({
    requestId,
    businessFundamentalFieldNames = BUSINESS_FUNDAMENTALS,
    tradingFundamentalFieldNames = TRADING_FUNDAMENTALS,
}: {
    requestId: string;
    businessFundamentalFieldNames?: Nullable<Array<string>>;
    tradingFundamentalFieldNames?: Nullable<Array<string>>;
}): Promise<InstrumentEvaluationResult | ApiError> => {
    try {
        const today = FormatUtcDate(new Date(), 'yyyy-MM-dd');
        const oneWeekAgo = FormatUtcDate(subDays(new Date(), 7), 'yyyy-MM-dd');
        const evaluationResponse: InstrumentEvaluationType = await GQL_CLIENT.request(
            INSTRUMENT_EVALUATION_RESULTS_QUERY,
            {
                businessFundamentalFieldNames,
                maxAsOfDate: today,
                minAsOfDate: oneWeekAgo,
                requestId,
                tradingFundamentalFieldNames,
            }
        );
        return evaluationResponse.instrumentEvaluationResult;
    } catch (error) {
        return { error: error as string };
    }
};

interface GetPartialInstrumentEvaluationResponseInput {
    requestId: string;
    customQueryName: CompanyModuleTypes;
    queryVariables?: {
        businessFundamentalFieldNames?: Nullable<Array<string>>;
        tradingFundamentalFieldNames?: Nullable<Array<string>>;
        minAsOfDate?: Date;
        maxAsOfDate?: Date;
    };
}

export const getPartialInstrumentEvaluationResponse = async ({
    requestId,
    customQueryName,
}: GetPartialInstrumentEvaluationResponseInput): Promise<InstrumentEvaluationResult | ApiError> => {
    const { name, fragment } = PARTIAL_COMPANY_FRAGMENTS[customQueryName] || {};

    try {
        const query = gql`
            ${fragment}
            query instrumentEvalutionResultsQuery(
                $requestId: ID!
            ) {
                instrumentEvaluationResult(requestId: $requestId) {
                    instrument {
                        id
                    }
                    isCompleted
                    ...${name}
                }
            }
        `;
        const evaluationResponse: InstrumentEvaluationType = await GQL_CLIENT.request(query, {
            requestId,
        });

        return evaluationResponse.instrumentEvaluationResult;
    } catch (error) {
        return { error: error as string };
    }
};

export const getInstrumentEvaluation = async ({
    organizationId,
    instrumentId,
    requestId,
    businessFundamentalFieldNames = BUSINESS_FUNDAMENTALS,
    tradingFundamentalFieldNames = TRADING_FUNDAMENTALS,
}: GetInstrumentEvaluationArgs): Promise<GetInstrumentEvaluationResponse | ClientError> => {
    let request: string | ClientError = requestId as string;

    try {
        if (!request) {
            request = await getInstrumentEvaluationRequest({ instrumentId, organizationId });

            if ((request as ClientError).response) {
                return request as ClientError;
            }
        }

        const evaluationResponse = await getInstrumentEvaluationResponse({
            businessFundamentalFieldNames,
            requestId: request as string,
            tradingFundamentalFieldNames,
        });

        if ((evaluationResponse as ApiError).error) {
            throw Error((evaluationResponse as ApiError).error);
        }

        return { evaluation: evaluationResponse, requestId: request } as GetInstrumentEvaluationResponse;
    } catch (error) {
        return error as ClientError;
    }
};

export const getRemainingInstrumentEvaluationRequests = async (): Promise<number | ApiError> => {
    try {
        const remainingEvaluationRequests = await GQL_CLIENT.request(INSTRUMENT_EVALUATION_REQUESTS_REMAINING);

        return remainingEvaluationRequests;
    } catch (error) {
        return { error: error as string };
    }
};

export const getUserEvaluationRequestCount = async (): Promise<number | ApiError> => {
    try {
        const currentUserEvaluationRequestCount = await GQL_CLIENT.request(INSTRUMENT_EVALUATION_REQUEST_COUNT);

        return currentUserEvaluationRequestCount;
    } catch (error) {
        return { error: error as string };
    }
};

export interface GetUserEvaluationRequestPermissionResponse {
    allowInstrumentEvaluationRequest: boolean;
}

export const getUserEvaluationRequestPermission = async (): Promise<
    GetUserEvaluationRequestPermissionResponse | ApiError
> => {
    try {
        const evaluationRequestALlowed = await GQL_CLIENT.request(ALLOW_INSTRUMENT_EVALUATION_REQUEST);

        return evaluationRequestALlowed;
    } catch (error) {
        return { error: error as string };
    }
};

export const getInstrumentsWithFundamentals = async (
    ids: Array<string>,
    fieldNames: Array<string>
): Promise<Map<string, Array<FundamentalType>>> => {
    const response = await GQL_CLIENT.request(GET_INSTRUMENT_FUNDAMENTALS_QUERY, { fieldNames, ids });
    const { instruments } = response as { instruments: Array<{ id: string; fundamentals: Array<FundamentalType> }> };
    return new Map(
        instruments.map(({ id, fundamentals }) => [
            id,
            [...fundamentals].sort((a, b) => fieldNames.indexOf(a.name) - fieldNames.indexOf(b.name)),
        ])
    );
};

export const updateInstrumentEvaluationComps = async ({
    baseInstrumentId,
    compInstrumentIds,
    organizationId,
}: SetInstrumentCompsRequest): Promise<SetInstrumentCompsResult | ClientError> => {
    try {
        const response = await GQL_CLIENT.request(SET_INSTRUMENT_COMPS_MUTATION, {
            input: {
                baseInstrumentId,
                compInstrumentIds,
                organizationId,
            },
        });

        return response.setInstrumentEvaluationComps;
    } catch (error) {
        const errorResponse = error as ClientError;
        return errorResponse;
    }
};
