export const START_PAGE_TAB_VIEW_QUERY_PARAM = 'tv';

export enum MainActionTabs {
    RESEARCH_TOPIC = 'researchTopic',
    STOCK_SCREENER = 'stockScreener',
    COMPANY_SEARCH = 'companySearch',
}

export const MAIN_NAV_RESEARCH_LINKS = {
    iconType: 'book',
    label: 'Research',
    subMenu: [
        {
            label: 'Research a topic',
            tab: MainActionTabs.RESEARCH_TOPIC,
            tabIndex: 3,
        },
        {
            label: 'Stock screener',
            tab: MainActionTabs.STOCK_SCREENER,
            tabIndex: 4,
        },
        {
            label: 'Find companies',
            tab: MainActionTabs.COMPANY_SEARCH,
            tabIndex: 5,
        },
    ],
    tabIndex: 2,
};

export const MAIN_NAV_BUILD_LINKS = {
    iconType: 'cube',
    label: 'Build',
    subMenu: [
        { href: '/index-builder/create', label: 'Search for securities', tabIndex: 7 },
        {
            href: '/index-builder/create',
            label: 'Load an existing portfolio',
            tabIndex: 8,
        },
        { href: '/index-builder/create/backtest', label: 'Backtest', tabIndex: 9 },
    ],
    tabIndex: 6,
};

export const MAIN_NAV_DIRECT_LINKS = [
    {
        href: '/how-to-use',
        label: 'How to use',
        tabIndex: 10,
    },
    // {
    //     label: 'Capabilities',
    //     subMenu: [
    //         { href: '/product', label: 'Thematic overview', tabIndex: 11 },
    //         {
    //             href: '/product/asset-management-product-development',
    //             label: 'Develop asset-backed products',
    //             tabIndex: 12,
    //         },
    //         { href: '/product/evaluation', label: 'Company evaluations', tabIndex: 13 },
    //         { href: '/backtesting-and-benchmarking', label: 'Backtesting and benchmarking', tabIndex: 14 },
    //         { href: '/thematic-benchmarks', label: 'Thematic branded indices', tabIndex: 15 },
    //     ],
    //     tabIndex: 10,
    // },
    {
        href: '/about-us',
        label: 'About us',
        tabIndex: 11,
    },
    {
        href: '/blog',
        label: 'Blog',
        tabIndex: 12,
    },
];
export const MAIN_NAV_LINKS = [MAIN_NAV_RESEARCH_LINKS, MAIN_NAV_BUILD_LINKS, ...MAIN_NAV_DIRECT_LINKS];

export enum MainFooterLinkGroups {
    PRODUCT = 'product',
    LEARN = 'learn',
    COMPANY = 'company',
}

export const MAIN_FOOTER_LINKS = [
    {
        group: {
            key: MainFooterLinkGroups.PRODUCT,
            label: 'Product',
        },
        navLinks: [
            { href: '/index-builder/create', label: 'Search for securities', tabIndex: 7 },
            {
                href: '/index-builder/create',
                label: 'Load an existing portfolio',
                tabIndex: 8,
            },
            { href: '/index-builder/create/backtest', label: 'Backtest', tabIndex: 9 },
        ],
    },
    {
        group: {
            key: MainFooterLinkGroups.LEARN,
            label: 'Learn',
        },
        navLinks: [
            { href: '/product', label: 'Thematic overview', tabIndex: 11 },
            {
                href: '/product/asset-management-product-development',
                label: 'Develop asset-backed products',
                tabIndex: 12,
            },
            { href: '/product/evaluation', label: 'Company evaluations', tabIndex: 13 },
            { href: '/backtesting-and-benchmarking', label: 'Backtesting and benchmarking', tabIndex: 14 },
            { href: '/thematic-benchmarks', label: 'Thematic branded indices', tabIndex: 15 },
            { href: '/web3', label: 'Build investment strategies with crypto', tabIndex: 16 },
        ],
    },
    {
        group: {
            key: MainFooterLinkGroups.COMPANY,
            label: 'Company',
        },
        navLinks: [
            {
                href: '/how-to-use',
                label: 'How to use',
                tabIndex: 17,
            },
            {
                href: '/about-us',
                label: 'About us',
                tabIndex: 18,
            },
            {
                href: '/blog',
                label: 'Blog',
                tabIndex: 19,
            },
        ],
    },
];
