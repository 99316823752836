'use client';

import { createContext, useContext } from 'react';
import { useLocalStorage } from 'react-use';

import {
    NewFeatureAnnouncementsStorageKeyMap,
    NewFeatureAnnouncementStorageKey,
    NewFeatureAnnouncementTypes,
} from '@/lib/constants/new-features';
import { Nullable } from '@/types/nullable';

export type NewFeatureAnnouncementMap = Record<NewFeatureAnnouncementTypes, boolean>;

export type NewFeatureAnnouncementContext = {
    hasAnnouncementBeenViewed: (newFeature: NewFeatureAnnouncementTypes) => boolean;
    newFeaturesKeys: typeof NewFeatureAnnouncementTypes;
    newFeatures: NewFeatureAnnouncementMap;
    updateNewFeatures: (newFeature: NewFeatureAnnouncementTypes, value: boolean) => void;
};

interface NewFeatureAnnouncementCProviderProps {
    children: React.ReactNode;
}

const defaultNewFeatureAnnouncementStorage: NewFeatureAnnouncementMap = Object.values(
    NewFeatureAnnouncementsStorageKeyMap
).reduce((featuresMap, storageKey) => {
    return {
        ...featuresMap,
        [storageKey]: false,
    };
}, {} as NewFeatureAnnouncementMap);

export const NewFeatureContext = createContext<Nullable<NewFeatureAnnouncementContext>>(null);

export const NewFeatureAnnouncementProvider = ({ children }: NewFeatureAnnouncementCProviderProps) => {
    const [storageValue, setStorageValue] = useLocalStorage(
        NewFeatureAnnouncementStorageKey,
        defaultNewFeatureAnnouncementStorage
    );
    const updateNewFeatures = (newFeatureKey: NewFeatureAnnouncementTypes, value: boolean) => {
        const updatedStorage = {
            ...storageValue,
            [NewFeatureAnnouncementsStorageKeyMap[newFeatureKey] as string]: value,
        } as NewFeatureAnnouncementMap;
        setStorageValue(updatedStorage);
    };
    const hasAnnouncementBeenViewed = (newFeatureKey: NewFeatureAnnouncementTypes) => {
        return (
            (storageValue as NewFeatureAnnouncementMap)?.[
                NewFeatureAnnouncementsStorageKeyMap[newFeatureKey] as NewFeatureAnnouncementTypes
            ] ?? false
        );
    };
    const value: NewFeatureAnnouncementContext = {
        hasAnnouncementBeenViewed,
        newFeatures: storageValue as NewFeatureAnnouncementMap,
        newFeaturesKeys: NewFeatureAnnouncementTypes,
        updateNewFeatures,
    };

    return <NewFeatureContext.Provider value={value}>{children}</NewFeatureContext.Provider>;
};

export const useNewFeatureAnnouncement = () => {
    const context: Nullable<NewFeatureAnnouncementContext> = useContext(NewFeatureContext);

    if (!context) {
        throw new Error('useNewFeatureAnnouncement must be used within a NewFeatureAnnouncementProvider');
    }

    return context;
};
