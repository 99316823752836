export enum TeamViews {
    DASHBOARD = 'dashboard',
    PROJECTS = 'projects',
    RESEARCH = 'research',
    INDICES = 'indices',
    MEMBERS = 'members',
    PROFILE = 'profile',
    ANALYSIS_RESULTS = 'analysis-results',
    COMPANY_EVALUATIONS = 'company-evaluations',
}
