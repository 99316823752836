export const NewFeatureAnnouncementStorageKey = 'new_feature_announcements';

export enum NewFeatureAnnouncementTypes {
    RESEARCH_CTA = 'research_new_feature_tooltip',
    RESEARCH_SIDEBAR_TOGGLE = 'research_sidebar_action_new_feature_tooltip',
    NEW_TEAMS_PROFILE_DROPDOWN_LINK = 'new_teams_profile_dropdown_link',
    NEW_TEAMS_DASHBOARD_WELCOME = 'new_teams_dashboard_welcome',
    NEW_TEAMS_SIDEBAR_NAVIGATION = 'new_teams_sidebar_navigation',
}

type NewFeatureAnnouncementsStorageKeyMapType = {
    [key in NewFeatureAnnouncementTypes]: string;
};

export const NewFeatureAnnouncementsStorageKeyMap: NewFeatureAnnouncementsStorageKeyMapType = Object.keys(
    NewFeatureAnnouncementTypes
).reduce((map, enumKey) => {
    return {
        ...map,
        [NewFeatureAnnouncementTypes[enumKey as keyof typeof NewFeatureAnnouncementTypes]]:
            NewFeatureAnnouncementTypes[enumKey as keyof typeof NewFeatureAnnouncementTypes],
    };
}, {} as NewFeatureAnnouncementsStorageKeyMapType);

export const NEW_TEAMS_USER_PROFILE_DROPDOWN_TEAM_LINK = [
    <>
        Check out the new updated team dashboard and pages. All of your team&apos;s work is now organized so that your
        can collaborate better and more efficiently.
    </>,
];

export const NewFeatureAnnouncementsCopyMap: {
    [key in NewFeatureAnnouncementTypes]: Array<React.ReactNode>;
} = {
    [NewFeatureAnnouncementTypes.RESEARCH_CTA]: [
        <>
            <span className="font-brand-bold text-analyst-blue text-xl">Thematic Research</span>
        </>,
        <>
            Save your work all in one place. Simply click the <span className="font-brand-bold">Bookmark</span> icon to
            later view, edit, annotate, and share your research and insights.
        </>,
    ],
    [NewFeatureAnnouncementTypes.RESEARCH_SIDEBAR_TOGGLE]: [
        <>
            <span className="font-brand-bold text-analyst-blue">Thematic Research</span>
        </>,
        <>
            View all of your research in one place. Toggle open the floating actions menu and click{' '}
            <span className="font-brand-bold">Open research sidebar</span> to view your saved research for the current
            page.
        </>,
    ],
    [NewFeatureAnnouncementTypes.NEW_TEAMS_PROFILE_DROPDOWN_LINK]: [
        <>
            Check out the new updated team dashboard and pages. All of your team&apos;s work is now organized so that
            your can collaborate better and more efficiently.
        </>,
    ],
    [NewFeatureAnnouncementTypes.NEW_TEAMS_DASHBOARD_WELCOME]: [
        <>
            All of your team&apos;s work is now organized in one place, enabling better and more efficient
            collaboration.
        </>,
    ],
    [NewFeatureAnnouncementTypes.NEW_TEAMS_SIDEBAR_NAVIGATION]: [
        <span
            key={`team-action-new-feature-tooltip-${NewFeatureAnnouncementTypes.NEW_TEAMS_SIDEBAR_NAVIGATION}`}
            className="font-brand-bold text-analyst-blue"
        >
            Sidebar navigation
        </span>,
        <>
            The new sidebar makes it easy to pivot from your team&apos;s research analyses, company research items,
            account management settings, and more.
        </>,
    ],
};
